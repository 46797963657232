<template>
  <tbody align="center">
    <tr>
      <td align="left">Puissance Max (kW)</td>
      <td />
      <td
        v-for="(temporalClass, i) of this.temporalClasses"
        :key="'temporalClass' + i"
      >
        <AppNumber
          :value="analysis.highestMeasurements[temporalClass].value / 1000 || 0"
        />
      </td>
    </tr>

    <tr>
      <td align="left">Date</td>
      <td />
      <td
        v-for="(temporalClass, i) of this.temporalClasses"
        :key="'temporalClass' + i"
      >
        {{
          formatDate(
            analysis.highestMeasurements[temporalClass].measureDatetime
          )
        }}
      </td>
    </tr>

    <tr>
      <td align="left">Consommation (MWh)</td>
      <td>
        <AppNumber :value="analysis.totalConsumption" />
      </td>
      <td
        v-for="(temporalClass, i) of this.temporalClasses"
        :key="'temporalClass' + i"
      >
        <AppNumber :value="analysis.consumptions[temporalClass]" />
      </td>
    </tr>

    <AggregateAnalysisContentTotalRow
      title="Puissance ARENH (kW)"
      :temporalClasses="temporalClasses"
      :value="analysis.arenhPower"
    />
    <AggregateAnalysisContentTotalRow
      title="Proportion Baseload"
      unit="%"
      :temporalClasses="temporalClasses"
      :value="analysis.baseloadCoefficient * 100"
    />
    <AggregateAnalysisContentTotalRow
      title="Proportion Peakload"
      unit="%"
      :temporalClasses="temporalClasses"
      :value="analysis.peakloadCoefficient * 100"
    />
    <AggregateAnalysisContentTotalRow
      title="Proportion ARENH"
      unit="%"
      :temporalClasses="temporalClasses"
      :value="analysis.arenhCoefficient * 100"
    />
  </tbody>
</template>

<script>
  import moment from 'moment';
  import AppNumber from '../app/AppNumber.vue';
  import AggregateAnalysisContentTotalRow from './AggregateAnalysisContentTotalRow';

  export default {
    name: 'AggregateAnalysisContent',
    components: {
      AppNumber,
      AggregateAnalysisContentTotalRow,
    },
    props: {
      temporalClasses: {
        type: Array,
        required: true,
      },
      analysis: {
        type: Object,
        required: true,
      },
    },
    methods: {
      formatDate(date) {
        return moment(date).format('DD/MM/YYYY');
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import 'src/client/style/table.scss';

  td {
    padding: 10px !important;
  }
</style>
