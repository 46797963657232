<template>
  <v-chip
    class="ma-2"
    :color="color"
    text-color="white"
    data-test="cdp-status-mesure"
  >
    {{ label }}
  </v-chip>
</template>

<script>
  export default {
    name: 'CourbeDePuissanceStatus',
    props: {
      status: {
        type: String,
        required: true,
      },
    },
    computed: {
      label() {
        switch (this.status) {
          case 'CREATE':
            return 'Consultation en attente';
          case 'ACTIVE':
            return 'Consultation en cours';
          case 'IN_PROGRESS':
            return 'Consultation Enedis en cours';
          case 'COMPLETED':
            return 'Données disponibles';
          case 'UNAVAILABLE':
            return 'Données incomplètes';
          default:
            return 'Erreur inattendue';
        }
      },
      color() {
        switch (this.status) {
          case 'CREATE':
            return 'grey darken-1';
          case 'ACTIVE':
            return 'var(--secondary-new)';
          case 'IN_PROGRESS':
            return 'var(--secondary-new)';
          case 'COMPLETED':
            return 'var(--green-new)';
          case 'UNAVAILABLE':
            return 'amber darken-1';
          default:
            return 'var(--red-new)';
        }
      },
    },
  };
</script>
