<template>
  <div class="app-number">
    <span>{{ formatNumber(value, showSign) }}</span>
    <span v-if="unit">{{ ' ' + unit }}</span>
  </div>
</template>

<script>
  export default {
    name: 'AppNumber',
    props: {
      value: { type: Number },
      unit: { type: String },
      showSign: { type: Boolean },
      showPositive: { type: Boolean },
    },
    methods: {
      formatNumber(value, showSign = false) {
        const formatter = new Intl.NumberFormat('fr-FR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });

        const formatted =
          value != null
            ? formatter.format(Math.abs(value)).replace(/\D00$/, '')
            : '--';

        return showSign && !!value
          ? `${value > 0 ? (this.showPositive ? '+' : '') : '-'} ${formatted}`
          : formatted;
      },
    },
  };
</script>

<style lang="scss">
  .app-number {
    white-space: nowrap;
  }
</style>
