<template>
  <tr v-if="profile.status === 'FAILED'">
    <td class="pa-5">
      {{ profile.prm }}
    </td>

    <td
      colspan="50"
      rowspan="2"
      :style="{ color: 'var(--red-new)' }"
      class="text-left pa-4"
    >
      {{ profile.message }}
    </td>
  </tr>
</template>

<script>
  export default {
    name: 'ErrorCourbeDePuissance',
    props: {
      profile: {
        type: Object,
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import 'src/client/style/table.scss';
  td {
    background-color: transparent !important;
  }
</style>
