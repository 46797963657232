<template>
  <tr>
    <td align="left">{{ title }}</td>
    <td>
      <AppNumber :value="value" :showSign="true" :unit="unit" />
    </td>
    <td v-for="i of this.temporalClasses" :key="'temporalClasse' + i"></td>
  </tr>
</template>

<script>
  import AppNumber from '../app/AppNumber.vue';

  export default {
    name: 'AggregateAnalysisContentTotalRow',
    components: {
      AppNumber,
    },
    props: {
      title: {
        type: String,
        required: true,
      },
      value: {
        type: Number,
        required: true,
      },
      temporalClasses: {
        type: Array,
        required: true,
      },
      unit: {
        type: String,
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import 'src/client/style/table.scss';

  td {
    padding: 10px !important;
  }
</style>
