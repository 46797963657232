<template>
  <thead>
    <tr>
      <th></th>
      <th>Total</th>
      <th
        v-for="(temporalClass, i) of this.temporalClasses"
        :key="'temporalClass' + i"
      >
        {{ temporalClass }}
      </th>
    </tr>
  </thead>
</template>

<script>
  export default {
    name: 'AggregateAnalysisHeader',
    components: {},
    props: {
      temporalClasses: {
        type: Array,
        required: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import 'src/client/style/table.scss';
</style>
