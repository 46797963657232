<template>
  <div class="d-flex flex-wrap">
    <v-btn
      v-for="index in nbTotalButton"
      :key="index"
      :value="year + index"
      class="mr-2 mt-1 mb-1 year-btn"
      outlined
      height="30"
      @click.prevent="$emit('selectShortcutYear', year + index)"
    >
      <span :style="{ color: 'var(--secondary)' }">{{ year + index }}</span>
    </v-btn>
  </div>
</template>

<script>
  import moment from 'moment-timezone';

  export default {
    name: 'AppDateSelectionShortcut',
    props: {
      startYearsSubstract: {
        type: Number,
        required: true,
      },
      nbTotalButton: {
        type: Number,
        required: true,
      },
    },
    data() {
      return {
        year: moment()
          .subtract(this.startYearsSubstract + 1, 'years')
          .year(),
      };
    },
  };
</script>

<style lang="scss" scoped>
  @import 'src/client/style/constants.scss';

  .year-btn.v-btn--outlined {
    border: thin solid var(--secondary);
  }
</style>
