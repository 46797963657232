var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{style:({ overflowX: 'auto' }),attrs:{"xs12":""}},[_c('v-col',{attrs:{"sm":"12"}},[_c('table',{attrs:{"data-test":"table-cdp"}},[_c('caption',[_vm._v(" Courbe de Puissance ")]),_c('thead',[_c('tr',[_c('th',[_vm._v("PRM")]),_c('th',[_vm._v("Date limite ACD")]),_c('th',[_vm._v("Disponibilité")]),_c('th',[_vm._v("Progression de la recherche")]),_c('th',[_vm._v("Action")])])]),_vm._l((_vm.data),function(profile,i){return _c('tbody',{key:profile.prm + i,class:{ light: i % 2 == 0, shadow: i % 2 == 1 }},[(profile.status === 'CREATE')?_c('tr',[_c('td',[_vm._v(" "+_vm._s(profile.prm)+" ")]),_c('td'),_c('td',[_c('CourbeDePuissanceStatus',{attrs:{"status":profile.status}})],1),_c('td'),_c('td',[_c('v-btn',{attrs:{"color":"secondary","x-small":"","plain":"","block":"","disabled":""}},[_c('v-icon',[_vm._v(" mdi-download ")]),_vm._v(" CSV ")],1)],1)]):_vm._e(),(profile.status === 'ACTIVE' && profile.enedisAuthorization)?_c('tr',[_c('td',[_vm._v(" "+_vm._s(profile.prm)+" ")]),(profile.enedisAuthorization.isAuthorized === true)?_c('td',[_vm._v(" "+_vm._s(_vm.formatDate(profile.enedisAuthorization.authorizedUntil))+" ")]):_c('td',{staticClass:"error-message"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.refresh),expression:"refresh"}]},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({style:({
                        color: 'var(--red-new)',
                        padding: '0.2rem',
                        fontSize: '2rem',
                      }),attrs:{"slot":"label"},slot:"label"},on),[_vm._v(" mdi-alert-circle ")])]}}],null,true)},[_c('span',{style:({ textAlign: 'center' })},[_vm._v(" Impossible de récupérer les mesures manquantes sans ACD valide ")])])],1),_c('div',[(profile.enedisAuthorization.authorizedUntil)?_c('p',{style:({ margin: '0', padding: '0' })},[_vm._v(" "+_vm._s(_vm.formatDate(profile.enedisAuthorization.authorizedUntil))+" ")]):_vm._e(),_vm._v(" ACD invalide ")])]),_c('td',[_c('CourbeDePuissanceStatus',{attrs:{"status":profile.status}})],1),_c('td',[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"var(--secondary)","size":"25"}})],1),_c('td',[_c('v-btn',{attrs:{"color":"secondary","x-small":"","plain":"","block":"","disabled":""}},[_c('v-icon',[_vm._v(" mdi-download ")]),_vm._v(" CSV ")],1)],1)]):_vm._e(),_c('ErrorCourbeDePuissance',{attrs:{"profile":profile}}),(
              profile.status === 'COMPLETED' ||
              profile.status === 'UNAVAILABLE' ||
              profile.status === 'IN_PROGRESS'
            )?_c('tr',[_c('td',[_vm._v(_vm._s(profile.prm))]),(profile.enedisAuthorization.isAuthorized === true)?_c('td',[_vm._v(" "+_vm._s(_vm.formatDate(profile.enedisAuthorization.authorizedUntil))+" ")]):_c('td',{staticClass:"error-message"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.refresh),expression:"refresh"}]},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({style:({
                        color: 'var(--red-new)',
                        padding: '0.2rem',
                        fontSize: '2rem',
                      }),attrs:{"slot":"label"},slot:"label"},on),[_vm._v(" mdi-alert-circle ")])]}}],null,true)},[_c('span',{style:({ textAlign: 'center' })},[_vm._v(" Impossible de récupérer les mesures manquantes sans ACD valide ")])])],1),_c('div',[(profile.enedisAuthorization.authorizedUntil)?_c('p',{style:({ margin: '0', padding: '0' })},[_vm._v(" "+_vm._s(_vm.formatDate(profile.enedisAuthorization.authorizedUntil))+" ")]):_vm._e(),_vm._v(" ACD invalide ")])]),_c('td',[_c('CourbeDePuissanceStatus',{attrs:{"status":profile.status}})],1),_c('td',[(
                  profile.status === 'COMPLETED' ||
                  profile.status === 'UNAVAILABLE'
                )?_c('div',[_c('div',[_vm._v(" Heures relevées disponibles : "+_vm._s(profile.courbePuissanceAvailability .nbAvailableMeasurements)+" ("+_vm._s(( (profile.courbePuissanceAvailability .nbAvailableMeasurements / profile.courbePuissanceAvailability.nbMeasurements) * 100 ).toFixed(2))+"%) ")]),_c('div',[_vm._v(" Heures relevées indisponibles : "+_vm._s(profile.courbePuissanceAvailability.nbMissingMeasurements)+" ("+_vm._s(( (profile.courbePuissanceAvailability .nbMissingMeasurements / profile.courbePuissanceAvailability.nbMeasurements) * 100 ).toFixed(2))+"%) ")])]):_c('div',{staticClass:"d-flex align-center"},[_c('v-col',{attrs:{"sm":"10"}},[_c('v-progress-linear',{attrs:{"value":(_vm.processedPart(profile) * 100) /
                      profile.courbePuissanceAvailability.nbPeriodsToFetch,"color":"primary","data-test":"searchProgress"}})],1),_c('v-col',{attrs:{"sm":"2"}},[_c('span',[_vm._v(" "+_vm._s(_vm.processedPart(profile))+" / "+_vm._s(profile.courbePuissanceAvailability.nbPeriodsToFetch)+" ")])])],1)]),_c('td',[_c('MeasurementDownloadModal',{attrs:{"profiles":[profile],"disabled":_vm.exportMeasurementDisableStatus(profile),"validation":_vm.validation},on:{"toggleSnackbarExport":function($event){return _vm.$emit('toggleSnackbarExport')}}})],1)]):_vm._e()],1)})],2)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }