<template>
  <v-container fluid>
    <v-row xs12 :style="{ overflowX: 'auto' }">
      <v-col sm="12">
        <table data-test="table-cdp">
          <caption>
            Courbe de Puissance
          </caption>
          <thead>
            <tr>
              <th>PRM</th>
              <th>Date limite ACD</th>
              <th>Disponibilité</th>
              <th>Progression de la recherche</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody
            v-for="(profile, i) in data"
            :key="profile.prm + i"
            :class="{ light: i % 2 == 0, shadow: i % 2 == 1 }"
          >
            <tr v-if="profile.status === 'CREATE'">
              <td>
                {{ profile.prm }}
              </td>
              <td></td>
              <td>
                <CourbeDePuissanceStatus
                  :status="profile.status"
                ></CourbeDePuissanceStatus>
              </td>
              <td></td>
              <td>
                <v-btn color="secondary" x-small plain block disabled>
                  <v-icon> mdi-download </v-icon>
                  CSV
                </v-btn>
              </td>
            </tr>

            <tr
              v-if="profile.status === 'ACTIVE' && profile.enedisAuthorization"
            >
              <td>
                {{ profile.prm }}
              </td>
              <td v-if="profile.enedisAuthorization.isAuthorized === true">
                {{ formatDate(profile.enedisAuthorization.authorizedUntil) }}
              </td>
              <td v-else class="error-message">
                <div v-show="refresh">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        slot="label"
                        v-on="on"
                        :style="{
                          color: 'var(--red-new)',
                          padding: '0.2rem',
                          fontSize: '2rem',
                        }"
                      >
                        mdi-alert-circle
                      </v-icon>
                    </template>
                    <span :style="{ textAlign: 'center' }">
                      Impossible de récupérer les mesures manquantes sans ACD
                      valide
                    </span>
                  </v-tooltip>
                </div>
                <div>
                  <p
                    :style="{ margin: '0', padding: '0' }"
                    v-if="profile.enedisAuthorization.authorizedUntil"
                  >
                    {{
                      formatDate(profile.enedisAuthorization.authorizedUntil)
                    }}
                  </p>
                  ACD invalide
                </div>
              </td>
              <td>
                <CourbeDePuissanceStatus
                  :status="profile.status"
                ></CourbeDePuissanceStatus>
              </td>
              <td>
                <v-progress-circular
                  indeterminate
                  color="var(--secondary)"
                  size="25"
                ></v-progress-circular>
              </td>
              <td>
                <v-btn color="secondary" x-small plain block disabled>
                  <v-icon> mdi-download </v-icon>
                  CSV
                </v-btn>
              </td>
            </tr>

            <ErrorCourbeDePuissance :profile="profile">
            </ErrorCourbeDePuissance>

            <tr
              v-if="
                profile.status === 'COMPLETED' ||
                profile.status === 'UNAVAILABLE' ||
                profile.status === 'IN_PROGRESS'
              "
            >
              <td>{{ profile.prm }}</td>
              <td v-if="profile.enedisAuthorization.isAuthorized === true">
                {{ formatDate(profile.enedisAuthorization.authorizedUntil) }}
              </td>
              <td v-else class="error-message">
                <div v-show="refresh">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        slot="label"
                        v-on="on"
                        :style="{
                          color: 'var(--red-new)',
                          padding: '0.2rem',
                          fontSize: '2rem',
                        }"
                      >
                        mdi-alert-circle
                      </v-icon>
                    </template>
                    <span :style="{ textAlign: 'center' }">
                      Impossible de récupérer les mesures manquantes sans ACD
                      valide
                    </span>
                  </v-tooltip>
                </div>
                <div>
                  <p
                    v-if="profile.enedisAuthorization.authorizedUntil"
                    :style="{ margin: '0', padding: '0' }"
                  >
                    {{
                      formatDate(profile.enedisAuthorization.authorizedUntil)
                    }}
                  </p>
                  ACD invalide
                </div>
              </td>
              <td>
                <CourbeDePuissanceStatus
                  :status="profile.status"
                ></CourbeDePuissanceStatus>
              </td>
              <td>
                <div
                  v-if="
                    profile.status === 'COMPLETED' ||
                    profile.status === 'UNAVAILABLE'
                  "
                >
                  <div>
                    Heures relevées disponibles :
                    {{
                      profile.courbePuissanceAvailability
                        .nbAvailableMeasurements
                    }}
                    ({{
                      (
                        (profile.courbePuissanceAvailability
                          .nbAvailableMeasurements /
                          profile.courbePuissanceAvailability.nbMeasurements) *
                        100
                      ).toFixed(2)
                    }}%)
                  </div>
                  <div>
                    Heures relevées indisponibles :
                    {{
                      profile.courbePuissanceAvailability.nbMissingMeasurements
                    }}
                    ({{
                      (
                        (profile.courbePuissanceAvailability
                          .nbMissingMeasurements /
                          profile.courbePuissanceAvailability.nbMeasurements) *
                        100
                      ).toFixed(2)
                    }}%)
                  </div>
                </div>
                <div v-else class="d-flex align-center">
                  <v-col sm="10">
                    <v-progress-linear
                      :value="
                        (processedPart(profile) * 100) /
                        profile.courbePuissanceAvailability.nbPeriodsToFetch
                      "
                      color="primary"
                      data-test="searchProgress"
                    >
                    </v-progress-linear>
                  </v-col>
                  <v-col sm="2">
                    <span>
                      {{ processedPart(profile) }} /
                      {{ profile.courbePuissanceAvailability.nbPeriodsToFetch }}
                    </span>
                  </v-col>
                </div>
              </td>
              <td>
                <MeasurementDownloadModal
                  :profiles="[profile]"
                  :disabled="exportMeasurementDisableStatus(profile)"
                  :validation="validation"
                  @toggleSnackbarExport="$emit('toggleSnackbarExport')"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import moment from 'moment';
  import CourbeDePuissanceStatus from './CourbeDePuissanceStatus';
  import ErrorCourbeDePuissance from './ErrorCourbeDePuissance';
  import MeasurementDownloadModal from './MeasurementDownloadModal';

  export default {
    name: 'CourbeDePuissanceTable',
    components: {
      CourbeDePuissanceStatus,
      ErrorCourbeDePuissance,
      MeasurementDownloadModal,
    },
    props: {
      data: {
        type: Array,
        required: true,
      },
      refresh: {
        type: Boolean,
        required: true,
      },
      validation: {
        type: Function,
        required: true,
      },
    },
    methods: {
      processedPart(profile) {
        if (profile.jobLeft || profile.jobLeft === 0) {
          return (
            profile.courbePuissanceAvailability.nbPeriodsToFetch -
            profile.jobLeft
          );
        }

        return 0;
      },
      formatDate(dateTime) {
        if (!dateTime) return '';
        return moment(dateTime).format('DD/MM/YYYY');
      },
      exportMeasurementDisableStatus(profile) {
        if (profile.status === 'COMPLETED' || profile.status === 'UNAVAILABLE')
          return false;
        return true;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import 'src/client/style/table.scss';
  @import 'src/client/style/constants.scss';
  .light {
    background-color: #f9f9f9 !important;
  }
  .shadow {
    background-color: #eeeeee !important;
  }
  td {
    background-color: transparent !important;
  }
</style>
