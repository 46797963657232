<template>
  <div :style="{ backgroundColor: 'var(--secondary-grey-new)' }">
    <v-snackbar
      v-model="isSnackbarOpen"
      :color="isSnackbarSuccess ? 'var(--green-new)' : 'var(--red-new)'"
      top
      timeout="5000"
      width="500px"
    >
      {{ snackbarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn
          small
          color="white"
          text
          v-bind="attrs"
          @click="isSnackbarOpen = false"
        >
          Fermer
        </v-btn>
      </template>
    </v-snackbar>

    <v-card class="pa-6" outlined>
      <v-row>
        <div :style="{ width: '35%', marginTop: '0.5rem' }">
          <v-row>
            <v-col md="6">
              <v-menu
                v-model="startDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="startDateInput"
                    :height="25"
                    :style="{ fontSize: '0.8em' }"
                    :error-messages="startDateErrors"
                    :error="!startDateBeforeEndDate"
                    label="Date de début (incluse)"
                    prepend-icon="mdi-calendar"
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="startDateInput"
                  no-title
                  :min="minDate"
                  :max="maxDate"
                  locale="fr-FR"
                  @input="
                    () => {
                      $v.startDate.$touch();
                      startDateMenu = false;
                    }
                  "
                  @blur="$v.startDate.$touch()"
                />
              </v-menu>
            </v-col>
            <v-col md="6">
              <v-menu
                v-model="endDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="endDateInput"
                    :height="25"
                    :style="{ fontSize: '0.8em' }"
                    :error-messages="endDateErrors"
                    :error="!startDateBeforeEndDate"
                    label="Date de fin (incluse)"
                    prepend-icon="mdi-calendar"
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="endDateInput"
                  no-title
                  :min="minDate"
                  :max="maxDate"
                  locale="fr-FR"
                  @input="
                    () => {
                      $v.endDate.$touch();
                      endDateMenu = false;
                    }
                  "
                  @blur="$v.endDate.$touch()"
                />
              </v-menu>
            </v-col>
          </v-row>
          <div>
            <div
              v-if="!startDateBeforeEndDate"
              align="center"
              :style="{
                margin: 0,
                padding: '0.1rem 0',
                fontSize: '0.6em',
                color: 'var(--red-new)',
              }"
            >
              La date de fin doit être postérieure à la date de début.
            </div>
            <div
              v-if="!periodIsYear"
              align="center"
              :style="{
                margin: 0,
                padding: '0.1rem 0',
                fontSize: '0.6em',
                color: '#d06802',
              }"
            >
              Attention, la période renseignée ne fait pas exactement une année.
            </div>
          </div>

          <DateSelectionShortcut
            :startYearsSubstract="1"
            :nbTotalButton="4"
            @selectShortcutYear="selectShortcutYear"
          />
        </div>

        <div :style="{ width: '65%' }">
          <div class="d-flex mt-4 ml-2 flex-wrap justify-space-between">
            <div>
              <div>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      small
                      class="ml-1"
                      color="primary"
                      block
                      :loading="isCdpLoading"
                      @click.prevent="fetchCdp"
                      v-bind="attrs"
                      v-on="on"
                    >
                      Lancer la consultation
                    </v-btn>
                  </template>
                  Affiche les données stockées en base
                </v-tooltip>
              </div>
            </div>

            <div>
              <div class="d-flex">
                <MeasurementImportModal
                  :key="measurementImportModalKey"
                  :validation="validation"
                  @toggleSnackbarImport="toggleSnackbarImport"
                />

                <div>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        x-small
                        plain
                        block
                        @click="exportCdpTemplate"
                        v-on="on"
                      >
                        <v-icon>mdi-export</v-icon>
                        Modèle
                      </v-btn>
                    </template>
                    Télécharger le modèle d'import de Puissance Active afin
                    d'importer vos Courbes de Puissance
                  </v-tooltip>
                </div>
                <div class="d-flex" v-if="hasCdp">
                  <MeasurementDownloadModal
                    :profiles="validProfiles"
                    :disabled="!validProfiles.length"
                    :validation="validation"
                    @toggleSnackbarExport="toggleSnackbarExport"
                  />
                </div>

                <div v-show="hasCdp">
                  <div>
                    <div>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            x-small
                            plain
                            block
                            :loading="isAnalyseLoading"
                            :disabled="isCdpLoading"
                            @click.prevent="fastAnalyse()"
                            v-on="on"
                          >
                            <v-icon>mdi-sigma</v-icon>
                            Analyse Agrégée
                          </v-btn>
                        </template>
                        Permet de récupérer les données de répartition de
                        consommations et les proportions BL, PL et ARENH
                      </v-tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex justify-end mt-4">
            <div v-show="showBtn">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn x-small plain block @click="refreshCdp" v-on="on">
                    <v-icon>mdi-refresh</v-icon>
                    Données Enedis
                  </v-btn>
                </template>
                Récupère les données auprès d'Enedis. L'ACD est obligatoire
              </v-tooltip>
            </div>

            <AppJobState :jobLeft="jobLeft" />
          </div>
        </div>
      </v-row>
    </v-card>

    <p v-if="hasCdp" class="mt-2 mb-2 ml-1" :style="{ fontSize: '0.8em' }">
      COURBES DE PUISSANCE DU {{ finalStartDate }} AU
      {{ finalEndDate }}
    </p>

    <v-card class="pa-4 mt-2" v-if="analysis" outlined>
      <v-row v-if="analysis" class="d-flex flex-column">
        <v-col>
          <AggregateAnalysisTable :analysis="analysis" v-if="!generalError" />
        </v-col>
      </v-row>
    </v-card>

    <v-card class="pa-4 mt-2" v-if="hasCdp" outlined>
      <v-row align="center">
        <v-col md="12">
          <CourbeDePuissanceTable
            v-if="!generalError"
            class="text-center"
            :data="profileList"
            :refresh="refresh"
            :validation="validation"
            @toggleSnackbarExport="toggleSnackbarExport"
          />

          <v-alert
            v-if="generalError"
            align="center"
            dense
            outlined
            type="error"
          >
            <div class="title">Erreur général</div>
            <pre>{{ JSON.stringify(generalError, null, '\t') }}</pre>
          </v-alert>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
  import moment from 'moment-timezone';
  import { saveAs } from 'file-saver';
  import { mapState } from 'vuex';
  import socket from '../../socket';
  import { required } from 'vuelidate/lib/validators';
  import {
    minPrmLengthArray,
    maxPrmLengthArray,
    formatPrmArray,
    isStartDateBeforeEndDate,
  } from '../../validators/enedisValidators';

  import { eJobStatus } from '../../services/TransportBudget.mapper';
  import AppJobState from '../app/AppJobState';
  import CourbeDePuissanceTable from './CourbeDePuissanceTable';
  import AggregateAnalysisTable from './AggregateAnalysisTable.vue';
  import DateSelectionShortcut from '../app/AppDateSelectionShortcut.vue';
  import businessDataService from '../../services/business-data/business-data.service';
  import MeasurementImportModal from './MeasurementImportModal';
  import MeasurementDownloadModal from './MeasurementDownloadModal';

  import {
    MatomoService,
    MATOMO_EVENTS,
  } from './../../services/MatomoApi.service';

  export default {
    name: 'CourbeDePuissanceTabs',
    components: {
      CourbeDePuissanceTable,
      AggregateAnalysisTable,
      DateSelectionShortcut,
      AppJobState,
      MeasurementImportModal,
      MeasurementDownloadModal,
    },
    props: {
      prms: {
        type: Array,
      },
      validation: {
        type: Function,
      },
      startDate: {
        type: String,
      },
      endDate: {
        type: String,
      },
      startDateBeforeEndDate: {
        type: Boolean,
      },
      periodIsYear: {
        type: Boolean,
      },
    },
    data() {
      return {
        startDateInput: this.startDate,
        endDateInput: this.endDate,
        generalError: null,
        isCdpLoading: false,
        analysis: null,
        isAnalyseLoading: false,
        startDateMenu: false,
        endDateMenu: false,
        finalStartDate: '',
        finalEndDate: '',
        maxDate: moment().add(10, 'years').format('YYYY-MM-DD'),
        minDate: moment().subtract(10, 'years').format('YYYY-MM-DD'),
        profileList: [],
        hasCdp: false,
        refresh: false,
        showBtn: false,
        snackbarMessage: '',
        isSnackbarOpen: false,
        isSnackbarSuccess: true,
        measurementImportModalKey: 0,
      };
    },
    computed: {
      ...mapState('auth', ['user']),
      ...mapState('measurements', ['jobLeft']),
      startDateErrors() {
        const errors = [];
        if (!this.$v.startDate.$dirty) return errors;
        !this.$v.startDate.required &&
          errors.push('Veuillez renseigner une date de début');
        return errors;
      },
      endDateErrors() {
        const errors = [];
        if (!this.$v.endDate.$dirty) return errors;
        !this.$v.endDate.required &&
          errors.push('Veuillez renseigner une date de fin');
        return errors;
      },
      validProfiles() {
        return this.profileList.filter(
          (profile) =>
            profile.status === eJobStatus.Completed ||
            profile.status === eJobStatus.Unavailable
        );
      },
    },
    watch: {
      profileList() {
        const finishedProfilesLength = this.profileList.filter(
          (e) =>
            e.status === eJobStatus.Completed ||
            e.status === eJobStatus.Failed ||
            e.status === eJobStatus.Unavailable
        ).length;

        if (finishedProfilesLength === this.profileList.length)
          this.isCdpLoading = false;
      },
      startDate() {
        this.startDateInput = this.startDate;
      },
      endDate() {
        this.endDateInput = this.endDate;
      },
      startDateInput() {
        this.$emit('startDateModifier', this.startDateInput);
        this.showBtn = false;
      },
      endDateInput() {
        this.$emit('endDateModifier', this.endDateInput);
        this.showBtn = false;
      },
    },
    validations: {
      prms: {
        required,
        minPrmLengthArray,
        maxPrmLengthArray,
        formatPrmArray,
      },
      startDate: { required, isStartDateBeforeEndDate },
      endDate: { required },
    },
    created() {
      socket.on('courbe-puissance:availability', (response) => {
        if (response.status === 'ACTIVE') {
          this.profileList = this.profileList.map((profile) => {
            if (profile.prm === response.prm && profile.status == 'CREATE') {
              return { ...profile, status: 'ACTIVE' };
            }
            return profile;
          });
        }

        if (response.status === 'COMPLETED') {
          this.updatePrmStatus(response);
          this.showBtn = true;
        }

        if (response.status === 'FAILED') {
          this.profileList = this.profileList.map((profile) => {
            if (profile.prm === response.prm) {
              return { ...profile, ...response };
            }
            return profile;
          });
        }
      });

      socket.on('courbe-puissance:fetch', (response) => {
        this.profileList = this.profileList.map((profile) => {
          if (profile.prm === response.prm) {
            if (!profile.courbePuissanceAvailability.nbPeriodsToFetch) {
              profile.courbePuissanceAvailability.nbPeriodsToFetch =
                response.jobLeft;
            }

            profile.status = 'IN_PROGRESS';

            if (response.jobLeft === 0) {
              this.fetchCdpAfterEnedisUpdate(profile);
            }

            return { ...profile, ...response };
          }

          return profile;
        });
      });
    },
    mounted() {
      MatomoService.trackPageView('Courbe de puissance');
    },
    methods: {
      async fetchCdp() {
        this.$v.$touch();
        this.validation();

        if (!this.$v.$invalid) {
          MatomoService.trackEvent(
            MATOMO_EVENTS.measurementsavailability,
            this.prms.length
          );

          this.resetForm();

          const startDate = moment(this.startDate);
          const endDate = moment(this.endDate).add(1, 'day');

          this.finalStartDate = startDate.format('DD/MM/YYYY');
          this.finalEndDate = moment(this.endDate).format('DD/MM/YYYY');

          try {
            this.isCdpLoading = true;

            for (const prm of [...new Set(this.prms)]) {
              this.profileList.push({ prm, status: 'CREATE' });
            }

            this.hasCdp = true;

            const response = await this.$http.get(
              '/courbe-puissance/availability',
              {
                withCredentials: true,
                params: {
                  username: this.user.displayName,
                  prms: [...new Set(this.prms)].join(','),
                  startDate: startDate.format('YYYY-MM-DDZ'),
                  endDate: endDate.format('YYYY-MM-DDZ'),
                },
              }
            );

            this.checkData(response);

            this.profileList.forEach((profile, i) => {
              const data = response.data;
              const profileIndex = data.findIndex((p) => {
                return p?.prm === profile.prm;
              });

              if (data[profileIndex].status === 'unknown')
                return this.createFailedProfile(
                  profile.prm,
                  'PRM absent de salesforce'
                );

              if (!data[profileIndex].transportCode)
                return this.createFailedProfile(
                  profile.prm,
                  "La FTA renseignée dans Salesforce n'est pas reconnue"
                );

              return this.profileList.splice(i, 1, {
                ...profile,
                enedisAuthorization: data[profileIndex].enedisAuthorization,
              });
            });
          } catch (err) {
            if (err.response?.data?.message)
              this.generalError = err.response.data.message;
            else this.generalError = err.message;
          }
        }
      },
      createFailedProfile(prm, message) {
        const profileIndex = this.profileList.findIndex((p) => {
          return p.prm === prm;
        });

        const failedProfile = {
          prm,
          status: eJobStatus.Failed,
          message: message ?? 'Une erreur est survenue',
        };

        return this.profileList.splice(profileIndex, 1, failedProfile);
      },
      async refreshCdp() {
        this.$v.$touch();
        this.validation();
        this.refresh = true;
        this.analysis = null;

        if (!this.$v.$invalid) {
          MatomoService.trackEvent(
            MATOMO_EVENTS.updateMeasurements,
            this.prms.length
          );

          let startDate;
          let endDate;

          try {
            const prms = this.profileList
              .filter(
                (profile) =>
                  profile.enedisAuthorization?.isAuthorized === true &&
                  profile.courbePuissanceAvailability?.nbMissingMeasurements > 0
              )
              .map((profile) => {
                startDate = moment(profile.request.startDate).format(
                  'YYYY-MM-DDZ'
                );
                endDate = moment(profile.request.endDate).format('YYYY-MM-DDZ');
                return profile.prm;
              });

            if (prms.length === 0) return;

            this.isCdpLoading = true;

            this.profileList = this.profileList.map((e) => {
              if (prms.includes(e.prm)) return { ...e, status: 'CREATE' };
              return e;
            });

            const response = await this.$http.post(
              '/courbe-puissance/fetch',
              {
                username: this.user.displayName,
                prms,
                startDate,
                endDate,
              },
              { withCredentials: true }
            );

            this.checkData(response);
          } catch (err) {
            if (err.response?.data?.message)
              this.generalError = err.response.data.message;
            else this.generalError = err.message;
          }
        }
      },
      async fetchCdpAfterEnedisUpdate(profile) {
        try {
          const response = await this.$http.get(
            '/courbe-puissance/availability',
            {
              withCredentials: true,
              params: {
                username: this.user.displayName,
                prms: profile.prm,
                startDate: moment(profile.request.startDate).format(
                  'YYYY-MM-DDZ'
                ),
                endDate: moment(profile.request.endDate).format('YYYY-MM-DDZ'),
              },
            }
          );

          this.checkData(response);
        } catch (err) {
          if (err.response?.data?.message)
            this.generalError = err.response.data.message;
          else this.generalError = err.message;
        }
      },
      updatePrmStatus(response) {
        const minMesure = Math.round(
          response.courbePuissance.courbePuissanceAvailability.nbMeasurements *
            0.015
        );

        const index = this.profileList.findIndex((p) => p.prm === response.prm);

        if (index === -1) return;

        const profile = this.profileList[index];

        profile.request = response.courbePuissance.request;
        profile.courbePuissanceAvailability =
          response.courbePuissance.courbePuissanceAvailability;
        profile.status =
          response.courbePuissance.courbePuissanceAvailability
            .nbMissingMeasurements > minMesure
            ? eJobStatus.Unavailable
            : eJobStatus.Completed;

        return this.profileList.splice(index, 1, profile);
      },
      async fastAnalyse() {
        this.$v.$touch();

        if (this.validation() && !this.$v.$invalid) {
          MatomoService.trackEvent(
            MATOMO_EVENTS.measurementsAnalysis,
            this.prms.length
          );

          const startDate = moment(this.startDate).toDate();
          const endDate = moment(this.endDate).add(1, 'day').toDate();

          try {
            this.isAnalyseLoading = true;

            const parameters = {
              prms: [...new Set(this.prms)],
              startDate,
              endDate,
            };
            this.analysis = await businessDataService.aggregatedAnalysis(
              parameters
            );
          } catch (err) {
            if (err.response?.data?.message)
              this.generalError = err.response.data.message;
            else this.generalError = err.message;
          } finally {
            this.isAnalyseLoading = false;
          }
        }
      },
      async exportCdpTemplate() {
        MatomoService.trackEvent(MATOMO_EVENTS.downloadMeasurementTemplate);

        try {
          const { blob, filename } =
            await businessDataService.downloadTemplateImportMeasurements();

          saveAs(blob, filename);
        } catch (err) {
          if (err.response?.data?.message)
            console.error(err.response.data.message);
          else console.error(err.message);
        }
      },
      selectShortcutYear(year) {
        this.startDateInput = year + '-01-01';
        this.endDateInput = year + '-12-31';
      },
      toggleSnackbarImport() {
        this.displaySnackbar(true, 'Données importées avec succès');
        this.measurementImportModalKey += 1;
      },
      toggleSnackbarExport() {
        this.displaySnackbar(
          true,
          'Une fois le csv prêt, vous recevrez un lien de téléchargement sur dans votre boite mail'
        );
      },
      displaySnackbar(isSuccess, message) {
        this.snackbarMessage = message;
        this.isSnackbarSuccess = isSuccess;
        this.isSnackbarOpen = true;
      },
      checkData(response) {
        if (!response) throw new Error('Aucune données disponible');
      },
      resetForm() {
        this.hasCdp = false;
        this.profileList = [];
        this.analysis = null;
        this.showBtn = false;
        this.refresh = false;
      },
    },
  };
</script>

<style lang="scss" scoped></style>
