<template>
  <div class="measurement-import-modal">
    <v-dialog v-model="modal" max-width="850px" @click:outside="closeModal">
      <template v-slot:activator="{ on: dialog }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn x-small plain block v-on="{ ...tooltip, ...dialog }">
              <v-icon>mdi-tray-arrow-down</v-icon>
              Import
            </v-btn>
          </template>
          Importer en CSV vos courbes de puissance
        </v-tooltip>
      </template>

      <v-card class="measurement-import-modal__card">
        <v-card-title>
          <span class="measurement-import-modal__card__title">
            Importer une courbe de puissance</span
          >
        </v-card-title>

        <v-card-text>
          <div class="measurement-import-modal__card__input-file">
            <div class="measurement-import-modal__card__input-label">
              Parcourir
            </div>
            <v-file-input
              class="measurement-import-modal__card__input-text"
              accept=".csv"
              truncate-length="35"
              placeholder="sélectionner un fichier"
              prepend-icon
              append-outer-icon="attach_file"
              @change="selectFile"
              :error="!file && $v.$dirty"
            />
          </div>

          <div class="measurement-import-modal__card__input-prm">
            <div class="measurement-import-modal__card__input-label">PDL</div>
            <v-text-field
              v-model="inputPrm"
              class="measurement-import-modal__card__input-text"
              placeholder="12345678901234"
              clearable
              :error="!inputPrm && $v.$dirty"
            />
          </div>

          <div class="measurement-import-modal__card__physical-magnitude">
            <v-radio-group v-model="selectedPhysicalMagnitude" :column="false">
              <template v-slot:label>
                <div class="measurement-import-modal__card__input-label">
                  Puissances
                </div>
              </template>
              <div
                v-for="(physicalMagnitude, i) in physicalMagnitudes"
                :key="'physicalMagnitude' + i"
                class="measurement-import-modal__card__physical-magnitude--radio"
              >
                <v-radio
                  class="pe-5"
                  :label="physicalMagnitude.label"
                  :value="physicalMagnitude.value"
                />
              </div>
            </v-radio-group>
          </div>

          <v-card-actions>
            <div v-if="errorMessage" class="error-message">
              {{ errorMessage }}
            </div>
            <v-spacer />
            <v-btn small color="primary" text @click="closeModal">
              Annuler
            </v-btn>
            <v-btn
              small
              color="primary"
              :loading="importProgress"
              @click.prevent="importCdp"
            >
              Importer
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { required } from 'vuelidate/lib/validators';
  import {
    minPrmLength,
    maxPrmLength,
  } from '../../validators/enedisValidators';
  import { mapState } from 'vuex';

  import businessDataService from '../../services/business-data/business-data.service';
  import { FetchablePhysicalMagnitude } from '../../services/business-data/business-data.interface';
  import {
    MatomoService,
    MATOMO_EVENTS,
  } from './../../services/MatomoApi.service';

  export default {
    name: 'MeasurementImportModal',
    props: {
      validation: {
        type: Function,
        required: true,
      },
    },
    data() {
      return {
        modal: false,
        importProgress: false,
        errorMessage: null,
        file: null,
        inputPrm: null,
        selectedPhysicalMagnitude: FetchablePhysicalMagnitude.ActivePower,
        physicalMagnitudes: [
          { label: 'Active', value: FetchablePhysicalMagnitude.ActivePower },
          {
            label: 'Réactive inductive',
            value: FetchablePhysicalMagnitude.InductiveReactivePower,
          },
          {
            label: 'Réactive capacitive',
            value: FetchablePhysicalMagnitude.CapacitiveReactivePower,
          },
        ],
      };
    },
    validations: {
      inputPrm: {
        required,
        minPrmLength,
        maxPrmLength,
      },
      file: { required },
      selectedPhysicalMagnitude: { required },
    },
    computed: {
      ...mapState('auth', ['user']),
    },
    methods: {
      selectFile(file) {
        this.file = file ? file : null;
      },
      closeModal() {
        this.modal = false;
        this.errorMessage = null;
      },
      async importCdp() {
        this.errorMessage = null;

        this.$v.$touch();
        this.validation();

        if (!this.$v.$invalid) {
          MatomoService.trackEvent(MATOMO_EVENTS.importMeasurements);

          try {
            this.importProgress = true;

            const parameters = {
              prm: this.inputPrm,
              physicalMagnitude: this.selectedPhysicalMagnitude,
              actor: this.user.displayName,
            };

            await businessDataService.importMeasurements(parameters, this.file);

            this.$emit('toggleSnackbarImport');
          } catch (err) {
            let message;
            if (err.response && err.response.data && err.response.data.message)
              message = err.response.data.message;
            else message = err.message;

            this.errorMessage = message;
          } finally {
            this.importProgress = false;
          }
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .measurement-import-modal {
    &__card {
      color: var(--grey);

      &__title {
        font-size: 1.5em;
        padding-bottom: 1rem;
      }

      &__input-file,
      &__input-prm {
        display: flex;
        width: 30rem;
      }

      &__input-label {
        align-self: center;
        font-weight: bold;
        margin-bottom: 1rem;
      }

      &__input-text {
        padding: 1rem 1rem 1rem 2rem;
      }

      &__physical-magnitude {
        display: flex;

        &--radio {
          padding-left: 2rem;
        }
      }
    }
  }
</style>
