<template>
  <div class="app-job-state">
    <v-icon :class="`app-job-state__icon ${jobState.color}`">{{
      jobState.icon
    }}</v-icon>
    <div class="app-job-state__message">{{ jobState.message }}</div>
  </div>
</template>

<script>
  export default {
    name: 'AppJobState',
    props: {
      jobLeft: {
        type: Number,
        Required: true,
      },
    },
    computed: {
      jobState() {
        const jobLeft = this.jobLeft;

        switch (true) {
          case jobLeft <= 180:
            return {
              icon: 'mdi-weather-sunny',
              color: 'app-job-state__icon--sunny',
              message: 'attente estimée à moins de 2 min',
            };
          case jobLeft > 180 && jobLeft <= 500:
            return {
              icon: 'mdi-cloud-outline',
              color: 'app-job-state__icon--cloud',
              message: 'attente estimée à moins de 10 min',
            };
          case jobLeft > 500 && jobLeft <= 3000:
            return {
              icon: 'mdi-weather-lightning',
              color: 'app-job-state__icon--storm',
              message: 'attente estimée à moins de 30 min',
            };
          case jobLeft > 3000:
            return {
              icon: 'mdi-skull-outline',
              color: 'app-job-state__icon--skull',
              message: 'attente estimée à plus de 30 min',
            };
          default:
            return {
              icon: 'mdi-alert-circle-outline',
              color: 'app-job-state__icon--skull',
              message: "Aucune information concernant l'attente",
            };
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import 'src/client/style/constants.scss';

  .app-job-state {
    display: flex;
    gap: 0.3rem;

    &__icon {
      align-self: self-start;

      &--sunny {
        color: var(--amber);
      }

      &--cloud {
        color: var(--secondary-new);
      }

      &--storm {
        color: var(--primary-new);
      }

      &--skull {
        color: var(--red);
      }
    }

    &__message {
      font-size: 0.75em;
      font-style: italic;
      margin-top: 0.2rem;
    }
  }
</style>
