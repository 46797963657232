<template>
  <v-container fluid>
    <v-row xs12 :style="{ overflowX: 'auto' }">
      <v-col>
        <table>
          <AggregateAnalysisHeader :temporalClasses="temporalClasses" />
          <AggregateAnalysisContent
            :temporalClasses="temporalClasses"
            :analysis="analysis"
          />
        </table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { orderedTemporalClass } from '../../constants/posteHorosaisonnierOrder.constants';
  import AggregateAnalysisHeader from './AggregateAnalysisHeader';
  import AggregateAnalysisContent from './AggregateAnalysisContent';

  export default {
    name: 'AggregateAnalysisTable',
    components: {
      AggregateAnalysisHeader,
      AggregateAnalysisContent,
    },
    props: {
      analysis: {
        type: Object,
        required: true,
      },
    },
    computed: {
      temporalClasses() {
        let temporalClasses = [];
        for (const temporalClass in this.analysis.consumptions) {
          orderedTemporalClass.forEach((ct) => {
            if (temporalClass == ct) {
              temporalClasses.push(ct);
            }
          });
        }

        return temporalClasses;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import 'src/client/style/table.scss';
</style>
